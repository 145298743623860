module.exports = {
  locales: ['ru', 'en'],
  defaultLocale: 'en',
  localesToIgnore: ['default'],
  pages: {
    '*': ['common', 'ads', 'error', 'admin_sidebar', 'modules_languagePannel'],
    '/404': ['404'],
    '/unsubscribe': ['unsubscribe'],
    '/mailing/confirm': ['unsubscribe'],
    '/list-companies': ['companiesList'],
    '/': ['home', 'company', 'modules_languagePannel'],
    '/terms-of-use': ['privacy_policy'],
    '/cookies-policy': ['privacy_policy'],
    '/privacy-policy': ['privacy_policy'],
    '/partner-public-agreement': ['partner_public'],
    '/contacts': ['contacts', 'company', 'thanks_application'],
    '/about-us': ['aboutUs', 'home'],
    '/comparison-of-passports': ['comparisonOfPassports'],
    '/visa-free-map': ['visaFreeMap'],
    '/companies': ['company', 'company_filter', 'thanks_application', 'preliminary_application'],
    'rgx:^/company': [
      'company',
      'france',
      'thanks_application',
      'auth_registrationPartner',
      'auth_login',
      'auth_registration_client',
      'preliminary_application',
    ],
    'rgx:^/accept': ['auth_reset_password'],
    'rgx:^/certificate': ['certificate'],
    '/tariffs': ['contacts', 'tariffs'],
    '/thanks-application': ['thanks_application'],
    '/certification-public-agreement': ['privacy_certification'],
    'rgx:^/cases': ['article'],
    'rgx:^/blog': ['article'],
    'rgx:^/news': ['article'],
    '/all-services': ['allServices'],
    '/ecological-strategy-of-company-international-world': ['ecological_strategy_of_company_international_world'],
    '/services': ['countries_services_template', 'company_filter', 'thanks_application'],
    '/services/residence-permit-serbia': ['vnzh_serbia', 'thanks_application'],
    '/services/residence-permit-business-serbia': ['vnzh_serbia_by_business', 'thanks_application'],
    'rgx:^/services/': ['countries_services', 'countries_services_template', 'thanks_application'],
    '/residence-permit-serbia': ['vnzh_serbia'],
    '/residence-permit-business-serbia': ['vnzh_serbia_by_business'],
    '/partnership': ['partnership'],
  },
};
